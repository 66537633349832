<template>
  <div>
    <h1>Trin 2/7: Deres demografiske træk</h1>
  </div>
  <div>
    <h3>Hvor gamle er de?</h3>
    <div class="rb_choices">
      <div class="p-field-radiobutton">
        <RadioButton id="rbUnder18" name="rbUnder18" value="Under 18 år" v-model="age" />
        <label for="rbUnder18">Under 18 år</label>
      </div>
      <div class="p-field-radiobutton">
        <RadioButton id="rb18to24" name="rb18to24" value="18 til 24" v-model="age" />
        <label for="rb18to24">18 til 24</label>
      </div>
      <div class="p-field-radiobutton">
        <RadioButton id="rb25to34" name="rb25to34" value="25 til 34" v-model="age" />
        <label for="rb25to34">25 til 34</label>
      </div>
      <div class="p-field-radiobutton">
        <RadioButton id="rb35to44" name="rb35to44" value="35 til 44" v-model="age" />
        <label for="rb35to44">35 til 44</label>
      </div>
      <div class="p-field-radiobutton">
        <RadioButton id="rb45to54" name="rb45to54" value="45 til 54" v-model="age" />
        <label for="rb45to54">45 til 54</label>
      </div>
      <div class="p-field-radiobutton">
        <RadioButton id="rb55to64" name="rb55to64" value="55 til 64" v-model="age" />
        <label for="rb55to64">55 til 64</label>
      </div>
      <div class="p-field-radiobutton">
        <RadioButton id="rb65andUp" name="rb65andUp" value="65 og ældr" v-model="age" />
        <label for="rb65andUp">65 og ældr</label>
      </div>
    </div>
  </div>
  <div>
    <h3>Hvad er den højeste uddannelse, som din persona har gennemført?</h3>
    <div class="rb_choices level_of_school">
      <div class="p-field-radiobutton">
        <RadioButton id="Grundskole" name="Grundskole" value="Grundskole" v-model="levelOfSchool" />
        <label for="Grundskole">Grundskole</label>
      </div>
      <div class="p-field-radiobutton">
        <RadioButton id="GymnasiumEllerTilsvarende" name="GymnasiumEllerTilsvarende" value="Gymnasium eller tilsvarende" v-model="levelOfSchool" />
        <label for="GymnasiumEllerTilsvarende">Gymnasium eller tilsvarende</label>
      </div>
      <div class="p-field-radiobutton">
        <RadioButton id="ErhvervsfagligeUddannelse" name="ErhvervsfagligeUddannelse" value="Erhvervsfaglige uddannelse" v-model="levelOfSchool" />
        <label for="ErhvervsfagligeUddannelse">Erhvervsfaglige uddannelse</label>
      </div>
      <div class="p-field-radiobutton">
        <RadioButton id="KortVideregåendeUddannelse" name="KortVideregåendeUddannelse" value="Kort videregående uddannelse" v-model="levelOfSchool" />
        <label for="KortVideregåendeUddannelse">Kort videregående uddannelse</label>
      </div>
      <div class="p-field-radiobutton">
        <RadioButton id="MellemlangVideregåendeUddannelse" name="MellemlangVideregåendeUddannelse" value="Mellemlang videregående uddannelse" v-model="levelOfSchool" />
        <label for="MellemlangVideregåendeUddannelse">Mellemlang videregående uddannelse</label>
      </div>
      <div class="p-field-radiobutton">
        <RadioButton id="Bacheloruddannelse" name="Bacheloruddannelse" value="Bacheloruddannelse" v-model="levelOfSchool" />
        <label for="Bacheloruddannelse">Bacheloruddannelse</label>
      </div>
      <div class="p-field-radiobutton">
        <RadioButton id="LangVideregåendeUddannelse" name="LangVideregåendeUddannelse" value="Lang videregående uddannelse" v-model="levelOfSchool" />
        <label for="LangVideregåendeUddannelse">Lang videregående uddannelse</label>
      </div>
      <div class="p-field-radiobutton">
        <RadioButton id="PhdOgForskeruddannelse" name="PhdOgForskeruddannelse" value="Ph.d. og forskeruddannelse" v-model="levelOfSchool" />
        <label for="PhdOgForskeruddannelse">Ph.d. og forskeruddannelse</label>
      </div>
    </div>
  </div>
</template>

<script>
import {computed} from "vue";
import store from "@/store/store";
export default {
  name: "DemographicTraitsQuestions",
  components: {},
  setup(){
    const age = computed({
      get: () => store.state.personaAge,
      set: val => {
        store.dispatch(('setPersonaAge'), {age: val})
      }
    })

    const levelOfSchool = computed({
      get: () => store.state.levelOfSchool,
      set: val => {
        store.dispatch(('setLevelOfSchool'), {level: val})
      }
    })

    return{
      age,
      levelOfSchool
    }
  }
}
</script>

<style scoped>
.rb_choices.level_of_school{
  flex-wrap: wrap;
}

.rb_choices.level_of_school .p-field-radiobutton{
  flex: 0 0 50%;
  max-width: 50%;
}

</style>